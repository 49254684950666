.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .header-padding {
    padding-top: 90px;

    @include md {
      padding-top: 141px;
    }

    @include xl {
      padding-top: 151px;
    }
  }
}