.subscribe {
  padding: 72px 0;
  background: $dark-green;
  text-align: center;

  h2 {
    margin-bottom: 20px;
    color: $yellow;
  }

  .cta {
    margin-bottom: 20px;
  }

  &__footnote {
    font-size: 14px;
    color: $blue;
  }
}