@include font-face(
  'Drahma',
  '../fonts/drahma/DharmaGothicE-Regular',
  400, // font weight
  null, // font style
  woff woff2
);
@include font-face(
  'Drahma',
  '../fonts/drahma/Flat-it-DharmaGothicE-Bold',
  700, // font weight
  null, // font style
  otf
);
@include font-face(
  'Drahma',
  '../fonts/drahma/Flat-it-DharmaGothicE-ExBold',
  900, // font weight
  null, // font style
  otf
);
@include font-face(
  'Open_Sans',
  '../fonts/Open_Sans/OpenSans-Regular',
  normal, // font weight
  null, // font style
  ttf
);