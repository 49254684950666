.info {
  padding: 48px 0;
  background: $beige;
  text-align: center;

  h4 {
    margin-bottom: 20px;
    color: $dark-green;
  }

  &__desc {
    margin-bottom: 20px;
    line-height: 1.2;
  }

  figure {
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }

  figure img {
    width: 100%;
    height: 100%;
    @include object-fit(contain, center);
  }
}