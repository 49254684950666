$ff-text: 'Open_Sans', sans-serif;
$ff-title: 'Drahma', serif;

$trs: all 0.2s ease-in;
$cubic: cubic-bezier(0.77, 0, 0.175, 0.75);
$trs-line: height 1.5s $cubic 1.5s;

// colors
$accent: #19304b;
$yellow: #F7B516;
$dark: #000000;
$dark-green: #153642;
$light: #ffffff;
$beige: #f3e8c7;
$blue: #65babf;