.vertical-title {
  position: absolute;
  top: 50%;
  transform-origin: top center;
  transform: translateX(-100%) rotate(-90deg);
  font: normal 900 154px/0.66 $ff-title;
  text-transform: uppercase;
  white-space: nowrap;
  color: $beige;
  transition: all 1s linear;

  @include md-max {
    font-size: 118px;
  }
}

*.aos-animate .vertical-title {
  transition-delay: 1.5s;
  transform: translateX(-50%) rotate(-90deg);
}