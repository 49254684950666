.cta {
  padding: 17px 53px;
  display: inline-flex;
  background: $yellow;
  border-radius: 4px;
  border: solid 3px $dark-green;
  font: normal 700 24px/1.2 $ff-title;
  text-align: center;
  text-transform: uppercase;
  color: $accent;
  transition: $trs;

  &:hover {
    opacity: 0.8;
  }
}

.cta.bg-blue {
  background: $blue;
}

.cta.border-light {
  border: solid 3px $beige;
}