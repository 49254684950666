html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow: auto;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

a[href],
button {
  cursor: pointer;
  user-select: none;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  list-style: none;
}

button {
  &:not(:disabled) {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

b, strong {
  font-weight: bold;
}

body {
  display: block;
  position: relative;
  font: normal normal 20px/1.65 $ff-text;
  background: #fff;
  min-width: 320px;
  color: $accent;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea {
  box-shadow: none;
}

::placeholder {
  // opacity: 0.7;
  font-weight: 300;
}

:-ms-input-placeholder {
  // opacity: 0.7;
  font-weight: 300;
}

::ms-input-placeholder {
  // opacity: 0.7
  font-weight: 300;;
}


[data-aos="stagger"] {
  > * {
    visibility: hidden;
  }

  &.aos-animate > * {
    visibility: visible;
  }
}