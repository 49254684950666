.container {
  padding: 0 32px;
  position: relative;

  @include md {
    padding: 0 32px;
  }

  @include lg {
    padding: 0 50px;
  }

  @include xl {
    max-width: 1180px;
    padding: 0;
    margin: 0 auto;
  }
}
