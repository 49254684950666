.sponsors {
  padding: 100px 0;
  background: $beige;
  text-align: center;

  h2 {
    margin-bottom: 30px;
  }

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  &__wrapper:before {
    content: '';
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 1px;
    background: $accent;
    transition: all 1.2s linear;
  }

  &__wrapper.aos-animate:before {
    transition-delay: 1s;
    width: 60%;

    @include md-max {
      width: 100%;
    }
  }

  &__item {
    width: 288px;
    margin: 30px;
  }

  &__item figure {
    width: 100%;
    height: 192px;
    margin-bottom: 36px;
  }

  &__item figure img {
    width: 100%;
    height: 100%;
    @include object-fit(contain, center);
  }

  h4 {
    color: $dark-green;

    span {
      position: relative;
      padding: 0 30px;
      background: $beige;

      @include md-max {
        padding: 0 10px;
      }
    }
  }
}