.form {
  max-width: 700px;
  margin: 0 auto;
  text-align: center;

  &__row {
    width: 100%;
    margin-bottom: 32px;
    display: flex;

    @include md-max {
      flex-direction: column;
    }
  }

  &__row.one-col label {
    width: 100%;
  }

  &__row.two-col label {
    width: 50%;
    margin-right: 20px;

    @include md-max {
      width: 100%;
      margin: 0 0 32px 0;
    }
  }

  &__row.two-col label:last-child {
    margin: 0;
  }

  input,
  textarea {
    width: 100%;
    max-height: 63px;
    padding: 17px 27px;
    background: $beige;
    border-radius: 4px;
    border: solid 3px $dark-green;
    resize: none;
    font: normal 700 24px/1 $ff-title;
    color: $accent;
    overflow: hidden;
  }

  &__footnote {
    margin-bottom: 32px;
    font-size: 14px;
  }
}