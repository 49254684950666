.hero {
  position: relative;
  padding: 60px 0 98px;
  background: $yellow;

  &__socials {
    text-align: right;
  }

  &__socials-inner {
    position: relative;
    padding: 0 3px 12px;
    display: inline-flex;
    justify-content: space-between;
  }

  &__socials-inner:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background: $accent;
    transition: all 2s;
  }

  &__socials-inner.aos-animate:after {
    transition-delay: 2.5s;
    width: 100vw;
  }

  &__socials-inner>a:not(:last-child) {
    margin-right: 20px;
  }

  &__social-item {
    width: 24px;
    height: 24px;
    fill: $accent;
    transition: $trs;
  }

  &__social-item:hover {
    opacity: 0.5;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    @include xl-max {
      padding-top: 40px;
    }

    @include md-max {
      flex-direction: column;
    }
  }

  &__logo {
    width: 46%;
    flex-shrink: 0;

    @include md-max {
      width: 80%;
      margin-bottom: 30px;
    }
  }

  &__logo img {
    width: 100%;
    height: 100%;
    max-height: 494px;
    @include object-fit(contain, center);
  }

  &__content {
    width: 54%;
    flex-shrink: 0;

    @include lg-max {
      padding-left: 40px;
    }

    @include md-max {
      width: 100%;
      padding-left: 0;
    }
  }

  h1 {
    margin-bottom: 10px;
  }

  h2 {
    margin-bottom: 20px;
  }

  &__desc {
    padding-right: 67px;

    @include md-max {
      padding: 0;
    }
  }

  &__desc p:not(:first-child) {
    margin-top: 20px;
  }

  .vertical-title {

    @include md-max {
      top: auto;
      bottom: 10%;
    }
  }
}