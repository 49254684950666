.product {
  padding: 100px 0;
  background: $dark-green;
  text-align: center;
  color: $beige;

  @include md-max {
    padding: 80px 0;
  }

  h3 {
    margin-bottom: 12px;
    text-transform: uppercase;
  }

  &__desc {
    margin-bottom: 40px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  a:hover &__item-inner {
    transform: scale(1.05);
  }

  &__item {
    width: 312px;
    margin: 40px 4px;
  }

  &__item-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    transition: $trs;
  }

  &__item figure {
    width: 100%;
    height: 192px;
    margin-bottom: 44px;
  }

  &__item figure img {
    width: 100%;
    height: 100%;
    @include object-fit(contain, center);
  }

  &__item h4 {
    margin-bottom: 4px;
  }

  &__item-price {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    font: normal 700 24px/1.2 $ff-title;
  }

  &__item-price:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 0;
    height: 1px;
    background: $beige;
    transition: all 1s;
  }

  &__item.aos-animate &__item-price:before {
    transition-delay: 1s;
    width: 100%;
  }

  &__item-price span {
    position: relative;
    padding-right: 18px;
    background: $dark-green;
  }

  &__item-desc {
    font-size: 16px;
  }

  &__item-desc strong {
    color: #F7B516;
  }
}