h1, h2, h3, h4 {
  margin: 0;
}

h1 {
  font: normal 900 84px/1.2 $ff-title;
  text-transform: uppercase;
  color: $beige;
}

h2 {
  font: normal 700 54px/1.2 $ff-title;
  text-transform: uppercase;
  color: $accent;
}

h3 {
  font: normal 700 54px/1.2 $ff-title;
  color: $yellow;
}

h4 {
  font: normal 400 34px/1.2 $ff-title;
  text-transform: uppercase;
  color: $yellow;
}