.items-with-buttons {
  padding: 60px 0;
  background: $beige;

  @include md-max {
    padding: 45px 0;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__item {
    max-width: 425px;
    margin: 40px 81px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include lg-max {
      max-width: 100%;
    }

    @include md-max {
      margin: 40px 0;
    }
  }

  &__item figure {
    width: 100px;
    height: 100px;
    margin-bottom: 24px;
  }

  &__item figure img {
    width: 100%;
    height: 100%;
    @include object-fit(contain,center);
  }

  &__item h2 {
    margin-bottom: 24px;
  }

  &__item h3 {
    margin-bottom: 24px;
  }

  &__item-desc {
    margin-bottom: 24px;
  }
}